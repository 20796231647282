const NOTIFICATION_TYPES = {
	NOTIFICATION_WITH_OK: 'NOTIFICATION_WITH_OK',
	NOTIFICATION_ERROR: 'NOTIFICATION_ERROR',
};

class NotificationsManager {
	constructor() {
		this.types = NOTIFICATION_TYPES;
		this.cartNotificationWithOk = document.querySelector('div#notification-with-ok');
		this.cartNotificationError = document.querySelector('div#cart-notification-error');

		const params = new URLSearchParams(window.location.search)
		if (params.get('login') === 'success') {
      this.open({
        type: this.types.NOTIFICATION_WITH_OK,
        body: "you are logged in!",
      });
		}
		if (params.get('login') === 'logout') {
      this.open({
        type: this.types.NOTIFICATION_WITH_OK,
        body: "you are logged out.",
      });
		}
		if (params.get('login') === 'failed_login') {
      this.open({
        type: this.types.NOTIFICATION_ERROR,
        body: `we're sorry, but we don't recognize your sign in.\n
				you may want to try resetting your password.\n
				if you need additional help, please email us at contact@cletile.com\n
				`
      });
		}
		if (params.get('login') === 'already_exists') {
      this.open({
        type: this.types.NOTIFICATION_ERROR,
        body: "a customer with the exact same email address already exists.",
      });
		}
		if (params.get('login') === 'password_reset') {
      this.open({
        type: this.types.NOTIFICATION_WITH_OK,
        body: "successfully changed password.",
      });
		}
	}

	open({ type, body, duration = 6000 }) {
		switch (type) {
			case this.types.NOTIFICATION_WITH_OK:
				this.cartNotificationWithOk.style.display = 'block';
				this.cartNotificationWithOk.querySelector('.message').innerHTML = body;
				this.cartNotificationWithOk.querySelector('#notificationAction1').addEventListener('click', () => {
					this.cartNotificationWithOk.style.display = 'none';
				})
				setTimeout(() => {
					if (this.cartNotificationWithOk) this.cartNotificationWithOk.style.display = 'none';
				}, duration);
				break;
			case this.types.NOTIFICATION_ERROR:
				this.cartNotificationError.style.display = 'block';
				this.cartNotificationError.querySelector('.message').innerHTML = body;
				this.cartNotificationError.querySelector('#notificationAction1').addEventListener('click', () => {
					this.cartNotificationError.style.display = 'none';
				})
				setTimeout(() => {
					if (this.cartNotificationError) this.cartNotificationError.style.display = 'none';
				}, duration);
				break;
			default:
				break;
		}
	}
}

const notificationsManager = new NotificationsManager();
window.notificationsManager = notificationsManager
