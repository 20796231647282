import '@pqina/flip/dist/flip.min.css';
import '../../styles/flip-clock.css';
import Tick from '@pqina/flip';

var locale = {
  HOUR_PLURAL: 'Hours',
  HOUR_SINGULAR: 'Hour',
  MINUTE_PLURAL: 'Mins',
  MINUTE_SINGULAR: 'Min',
  SECOND_PLURAL: 'Secs',
  SECOND_SINGULAR: 'Sec',
};

document.addEventListener("DOMContentLoaded", () => {
  let countdownFlip = document.querySelector('.countdown-flip');
  let countdownPencil = document.querySelector('.countdown-pencil');

  function initializeTimer(element) {
    if (!element) return;

    let counter = Tick.count.down(new Date(element.parentElement.dataset.end), 
      {
        format: ['h', 'm', 's']
      }
    );
    let tick = Tick.DOM.create(element, {
      credits: false,
    });

    for (var key in locale) {
      if (!locale.hasOwnProperty(key)) { continue; }
      tick.setConstant(key, locale[key]);
    }
  
    counter.onupdate = function (value) {
      tick.value = value;
    };
  }

  initializeTimer(countdownFlip);
  initializeTimer(countdownPencil);
});