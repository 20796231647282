import { drawer } from "../scripts/main/drawer";
import "../scripts/main/flip-clock";
import "../scripts/main/notifications-manager";

window.setLengthLimitForInput = (input, maxLength = 4) => {
  maxLength = parseInt(input.getAttribute('maxlength')) || maxLength;
  if (input.value.length > maxLength) {
    input.value = input.value.slice(0, maxLength);
  }
}

window.cletile = {}
window.cletile.module = { drawer };